/** @jsxImportSource theme-ui */

import * as React from 'react'
import { Box, Button, Heading } from "@theme-ui/components"
import { StaticImage } from "gatsby-plugin-image"
import Typist from 'react-typist';
import { useResponsiveValue, useBreakpointIndex } from '@theme-ui/match-media'

interface HeroProps {

}
const TypeAnimation = () => (
    <Typist>
        Mein Name ist Boris,<br />
        ich unterrichte
        <Typist.Delay ms={500} />
        <Typist.Backspace count={11} />
        berate
        <Typist.Delay ms={500} />
        <Typist.Backspace count={6} />
        programmiere
        <Typist.Delay ms={500} />
        <Typist.Backspace count={12} />
        erstelle Webseiten
        <Typist.Delay ms={500} />
        <Typist.Backspace count={18} />
        löse Probleme.
    </Typist>
)

const DesktopHero = () => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
        }}
    >
        <Box sx={{ minWidth: "600px" }}>
            <Heading sx={{ fontSize: "300%", mb: 3 }}>Hallo, Welt!</Heading>
            <Heading sx={{ fontSize: "300%" }}><TypeAnimation /></Heading>
        </Box>
        <Box sx={{ ml: 5 }}>
            {/*<StaticImage*/}
            {/*    src="../images/logo_1.svg"*/}
            {/*    alt="Logo"*/}
            {/*    layout="fixed"*/}
            {/*    placeholder="none"*/}
            {/*    loading="eager"*/}
            {/*    formats={["svg"]}*/}
            {/*/>*/}
            <img src="/logo_1.svg" alt="Logo" width="270"/>
        </Box>
    </Box>
)

const ResponsiveHero = () => (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        width: "100%"
    }}>
        <Box sx={{ mb: 0, justifyContent: "stretch" }}>
            <StaticImage
                src="../images/logo_1_round.png"
                alt="Logo"
                placeholder="primary"
                loading="eager"
                sx={{ m: 4 }}
            />
        </Box>
        <Box sx={{ flex: 1, width: "90%" }}>
            <Heading sx={{ fontSize: "150%", mb: 3 }}>Hallo, Welt!</Heading>
            <Heading sx={{ fontSize: "150%" }}><TypeAnimation /></Heading>
        </Box>
    </Box>
)

const Hero: React.FC<HeroProps> = (props) => {
    const index = useBreakpointIndex()

    return (
        <Box
            sx={{
                backgroundColor: 'primary',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: "center",
                justifyContent: "top"
            }}
        >
            <Box sx={{ width: '100%', p: 2 }}>
                <Heading>@bdudelsack</Heading>
            </Box>
            { index == 3 ? <DesktopHero/> : <ResponsiveHero />}
            <Box sx={{ width: '100%', p: 2, textAlign: 'center' }}>
                <a sx={{ color: '#000' }} href="#content"><i className="fas fa-2x fa-chevron-circle-down" /></a>
            </Box>

        </Box>
    )
}

export default Hero