import * as React from 'react'
import { Box } from "@theme-ui/components"

interface ContentProps {

}

const Content: React.FC<ContentProps> = (props) => (
    <Box variant="layout.content">
        <a id="content" />
        {props.children}
    </Box>
)

export default Content
