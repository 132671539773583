import * as React from "react"
import Header from "./Header"
import Footer from "./Footer"
import Content from "./Content"
import { Box, Container } from "@theme-ui/components"
import SEO from "../SEO"
import { IGatsbyImageData } from "gatsby-plugin-image"
import Hero from "../Hero"
import "../../styles/index.css"

interface MainPayoutProps {
    title?: string,
    image?: IGatsbyImageData,
}

const HeroLayout: React.FC<MainPayoutProps> = ({ title, image, children }) => (
    <>
        <Hero />
        <SEO title={title} />
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Box sx={{ width: '100%', flex: '1 1 auto' }}>
                <Container sx={{ mx: 'auto' }}>
                    {/*<Header image={image} />*/}
                    <Content>{children}</Content>
                    <Footer />
                </Container>
            </Box>
        </Box>
    </>
)

export default HeroLayout
