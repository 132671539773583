import * as React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql, Link } from "gatsby"
import DefaultLayout from "./layout/DefaultLayout"
import { getImage } from "gatsby-plugin-image"
import HeroLayout from "./layout/HeroLayout"

const shortcodes = { Link } // Provide common components here

export default function DefaultPage({ data: { mdx } }) {
    const Layout = mdx.frontmatter?.layout == 'hero' ? HeroLayout : DefaultLayout

    return (
        <Layout title={mdx.frontmatter?.title} image={getImage(mdx.frontmatter?.image)}>
            <MDXProvider components={shortcodes}>
                <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
        </Layout>
    )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        layout
        image { 
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
      }
    }
  }
`
