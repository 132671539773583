import * as React from "react"
import { Box } from "@theme-ui/components"
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

interface HeaderProps {
    image?: IGatsbyImageData,
}

const Header: React.FC<HeaderProps> = ({ image }) => (
    <>
        <Box id="header" variant="layout.header">
            <Link to="/">
            { image ? <GatsbyImage image={image} alt="header" /> : <StaticImage src="../../images/header.jpg" alt="header" /> }
            </Link>
        </Box>
    </>
)

export default Header
