import * as React from "react"
import { Box } from "@theme-ui/components"
import { Link } from "gatsby"

import "@fortawesome/fontawesome-free/css/all.css"

const Footer = () => (
    <>
        <Box variant="layout.footer">
            <p>

                <a href="https://twitter.com/bdudelsack">
                  <span className="fa-stack fa-lg">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-twitter fa-stack-1x fa-inverse"></i>
                  </span>
                </a>

                <a href="https://www.facebook.com/bdudelsack">
                  <span className="fa-stack fa-lg">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-facebook-f fa-stack-1x fa-inverse"></i>
                  </span>
                </a>

                <a href="https://github.com/bdudelsack">
                  <span className="fa-stack fa-lg">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-github fa-stack-1x fa-inverse"></i>
                  </span>
                </a>

            </p>

            <p>Copyright by Boris Dudelsack (C) {new Date().getFullYear()}</p>
            <p><Link to="/impressum">Impressum</Link> | <Link to="/datenschutz">Datenschutz</Link></p>
        </Box>
    </>
)

export default Footer
